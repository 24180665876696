body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #010101;
  line-height: 1.4;
}
h2 {
  &.title {
    font-size: 24px;
    color: #000;
    line-height: 1;
    font-weight: 300;
    text-align: left;
    position: relative;
    padding: 0;
    margin: 0 0 20px 0;
    @media (max-width: 767px) {
      font-size: 28px;
    }
    &.white {
      color: #fff;
    }
    &.dark {
      color: #fff;
    }
  }
}
.sub-title {
  font-size: 30px;
  color: #000;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 24px;
  }
  a {
    color: #2b3542;
    text-decoration: none;
    border-bottom: 2px solid #2b3542;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  &.dark {
    color: #fff;
    a {
      color: #fff;
      border-bottom: 2px solid #fff;
      &:hover {
        border-bottom-color: transparent;
      }
    }
  }
}
p {
  text-indent: 30px;
  &.big-text {
    font-size: 18px;
  }
}

#toTop {
  border: none;
  background: transparent;
  position: fixed;
  bottom: 40px;
  left: 20px;
  cursor: pointer;
  display: none;
  width: 56px;
  height: 56px;
  &:before {
    position: absolute;
    z-index: 1;
    font-family: "metpromstroy" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\41";
    font-size: 56px;
    color: #000;
    transition: all .3s linear;
  }
  &:hover {
    &:before {
      color: darken(#85a1ca, 10%);
    }
    &:after {
      color: darken(#fff, 10%);
    }
  }
  @media only screen and (max-width: 568px) {
    display: none !important;
  }
}
.bottom-in-content {
  margin-top: 15px;
}
a:focus {
  outline: 0;
  outline-offset: 0;
}

.page-title {
  font-size: 32px;
  font-weight: 300;
  color: #4859a5;
  margin: 30px 0;
}

input {
  &:focus {
    outline: 0;
    outline-offset: 0;
    text-decoration: none;
  }
}

button {
  &:focus {
    outline: 0;
    outline-offset: 0;
    text-decoration: none;
  }
}