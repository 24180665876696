.breadcrumb {
  padding: 0;
  margin: 15px 0;
  background-color: transparent;
  font-size: 16px;
  > li {
    + li {
      &:before {
        color: #000;
        content: " ";
        padding: 0;
      }
    }
    .divider {
      padding: 0 5px;
      &:first-child {
        padding: 0;
      }
    }
  }
  .active {
    color: #a0a7ae;
  }
}