.categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  .item{
    margin: 15px;
    transition: all .3s linear;
    display: flex;
    justify-content: space-between;
    .name {
      a {
        font-size: 18px;
        color: #2b3542;
        line-height: 1;
        display: block;
        text-align: center;
        text-decoration: none;
      }
    }
    &:hover {

      transition: all .3s linear;
    }
  }
}