.about {
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .wrapper-about {
    display: flex;
    .about-item {
      width: 33.33%;
      display: flex;
      align-items: center;
      padding: 20px;
      position: relative;
      margin: 15px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      > div {
        &:first-child {
          &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 35px;
            height: 25px;
            border-left: 1px solid #000;
            border-top: 1px solid #000;
          }
          &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            width: 35px;
            height: 25px;
            border-right: 1px solid #000;
            border-top: 1px solid #000;
          }
        }
      }
      .icon-fast-delivery {
        font-size: 58px;
        color: #00b7f4;
        line-height: 1;
      }
      .icon-money {
        font-size: 48px;
        color: #00b7f4;
        line-height: 1;
      }
      .icon-certificat {
        font-size: 48px;
        color: #00b7f4;
        line-height: 1;
      }
      .about-text {
        font-size: 18px;
        padding: 0 0 10px 0;
        margin: 15px;
        border-bottom: 2px solid #4859a5;
        &:before {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          width: 35px;
          height: 25px;
          border-left: 1px solid #000;
          border-bottom: 1px solid #000;
        }
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          right: 0;
          width: 35px;
          height: 25px;
          border-right: 1px solid #000;
          border-bottom: 1px solid #000;
        }
      }
    }
  }
}