.wrapper-box-services {
  padding-bottom: 70px;
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .services {
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
    .services-item {
      padding-left: 15px;
      padding-right: 15px;
      flex: 1 1 250px;
      @media (max-width: 767px) {
        flex: 1 1 156px;
        padding-bottom: 15px;
        padding-right: 0;
      }
      .services-item-bg {
        background: url(/templates/metpromstroy/images/bg-category.png);
        .services-item-more {
          color: #00b7f4;
          font-size: 16px;
        }
        .services-item-box {
          padding-bottom: 20px;
          text-align: center;
          .services-item-title {
            font-size: 18px;
            margin-bottom: 35px;
            padding-left: 25px;
            position: relative;
            text-align: left;
            @media (max-width: 767px) {
              font-size: 14px;
              margin-bottom: 15px;
              margin-top: 15px;
            }
            &:before {
              content: '';
              position: absolute;
              width: 26px;
              height: 2px;
              display: block;
              background: #4859a5;
              left: -11px;
              top: calc(50% - 1px);
            }
          }
          .services-item-more {
            font-size: 16px;
            position: relative;
            padding-bottom: 5px;
            display: inline-block;
            a {
              color: #000;
              font-size: 16px;
              text-decoration: none !important;
              display: inline-block;
              position: relative;
              transition: color 0.2s ease-out 0s;
              @media (max-width: 767px) {
                font-size: 14px;
              }
              &:after {
                font-family: "metpromstroy" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\70";
                font-size: 24px;
                color: #000;
                position: relative;
                top: -6px;
                right: -7px;
                transition: right 0.2s ease-out 0s;
              }
              &:hover {
                color: #4859a5;
                transition: color 0s ease-in 0.3s;
                &:after {
                  right: -10px;
                  transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
                }
              }
              span {
                overflow: hidden;
                display: inline-block;
                position: relative;
                padding: 0 0 7px;
                &:before {
                  content: '';
                  display: block;
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 68px;
                  height: 1px;
                  background-color: #000;
                  transition: all 0.4s ease;
                }
                &:hover {
                  &:before {
                    left: 100%;
                    background-color: #00b7f4;
                    transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
                  }
                }
              }
            }
          }
        }
        .services-item-img {
          font-size: 100px;
          text-align: center;
          margin-bottom: -28px;
          @media (max-width: 767px) {
            font-size: 62px;
          }
        }
        &:hover {
          .services-item-img {
            transition: color .3s linear;
            color: #4859a5;
          }
          .services-item-more {
            a {
              color: #00b7f4;
              transition: color .3s linear;
            }
            /*&:after {
              width: 0;
              transition: width .3s linear;
            }*/
          }
        }
      }
    }
  }
}