.fixed-menu-mobile {
  border-top: 3px solid #4859a5;
  position: fixed;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px #000;
  z-index: 1;
  .navbar-header {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .menu-toggle {
      float: left;
      background: transparent;
      border: none;
      color: #4859a5;
      font-size: 28px;
      line-height: 1;
      margin-right: -44px;
      &:hover {
        color: darken(#4859a5, 15%);
      }
    }
    .logo {
      img {
        height: 28px;
      }
    }
  }
  .menu-list {
    max-height: 320px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    > .menu {
      > li {
        padding-top: 5px;
        padding-bottom: 5px;
        > a {
          padding: 0;
          font-size: 18px;
          background: transparent;
          &:hover, &:focus {
            background: transparent;
          }
        }
        > ul {
          display: none;
        }
      }
    }
  }
}
.menu-mobile {
  background: #fff;
  padding-top: 64px;
  margin-bottom: -64px;
  .navbar-header {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    .menu-toggle {
      float: left;
      background: transparent;
      border: none;
      color: #4859a5;
      font-size: 28px;
      line-height: 1;
      margin-right: -44px;
      &:hover {
        color: darken(#4859a5, 15%);
      }
    }
    .phone {
      font-size: 22px;
    }
  }
  .menu-list {
    > .menu {
      margin-left: 15px;
      margin-right: 15px;
      > li {
        padding-top: 5px;
        padding-bottom: 5px;
        &.item-101 {
          display: none;
        }
        > a {
          padding: 0;
          font-size: 18px;
          background: transparent;
          &:hover, &:focus {
            background: transparent;
          }
          &.toggle-products {
            display: none;
          }
        }
        > ul {
          display: none;
        }
      }
    }
  }
}