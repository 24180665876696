.sidebar {
  > div {
    margin-bottom: 50px;
  }
  .je_acc {
    > ul {
      margin-left: 11px;
      > li {
        margin-bottom: 12px !important;
        > a {
          background: url(/templates/metpromstroy/images/bg-category.png) !important;
          border: none !important;
          font-size: 16px;
          position: relative;
          padding: 12px 26px !important;
          &:before {
            content: '';
            position: absolute;
            width: 26px;
            height: 2px;
            display: block;
            background: #000;
            left: -11px;
            top: calc(50% - 1px);
          }
          .holder {
            transform: rotate(0) !important;
            top: auto;
            &:before {
              font-family: "metpromstroy" !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              border: none;
              content: "\61";
              color: #6372b2;
              transform: rotate(0) !important;
              font-size: 14px;
              top: auto;
              width: auto;
              height: auto;
            }
            &:after {
              display: none;
            }
          }
        }
        &.open {
          > a {

            .holder {
              &:before {
                transform: rotate(180deg) !important;
              }
            }
          }
        }
        &.active {
          > a {
            color: #4859a5 !important;
            &:before {
              background: #4859a5;
            }
          }
        }
      }
      ul {
        margin-left: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
        li {
          > a {
            background: transparent !important;
            border: none !important;
            color: #000 !important;
            font-size: 14px;
            &:after {
              border-color: #000 !important;
            }
          }
          &.active {
            > a {
              color: #4859a5 !important;
            }
          }
        }
      }
    }
  }
  .moduletable {
    > h3 {
      font-size: 18px;
      padding: 0 0 12px 0;
      margin: 0 0 18px 0;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 82px;
        height: 1px;
        display: block;
        background: #4859a5;
        left: 0;
        bottom: 0;

      }
    }
    #jshop_unijax_filter {
      [class^=uf_label_] {
        background: url(/templates/metpromstroy/images/bg-category.png) !important;
        border: none !important;
        font-size: 16px;
        position: relative;
        padding: 12px 26px !important;
        font-weight: normal;
        span {
          color: #000;
        }
        .uf_trigon {
          display: none;
        }
        &:before {
          content: '';
          position: absolute;
          width: 26px;
          height: 2px;
          display: block;
          background: #000;
          left: -11px;
          top: calc(50% - 1px);
        }
        &:after {
          font-family: "metpromstroy" !important;
          font-style: normal !important;
          font-weight: normal !important;
          font-variant: normal !important;
          text-transform: none !important;
          speak: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\61";
          color: #6372b2;
          transform: rotate(0);
          font-size: 14px;
          top: calc(50% - 5px);
          right: 20px;
          position: absolute;
        }
        &.uf_close {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
      .uf_options {
        border: none;
        [class^=uf_options_] {
          max-height: 100px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        #uf_prices {
          max-height: 100%;
        }
      }
      .uf_trackbar_inputs {
        margin-top: 15px;
        input {
          border: none;
          border-bottom: 1px solid #000;
          padding: 8px 11px 8px 11px !important;
          font-size: 16px;
          color: #919191;
          text-align: left;
          position: relative;
          height: auto;
          &:after {
            position: relative;
            content: 'р.';
          }
          &:last-child {
            margin-left: 25px;
          }
        }
      }
      .uf_inputreset {
        display: none;
      }
      .trackbar {
        margin-top: 15px;
        .limit {
          display: none;
        }
        .centerBlock {
          height: 1px;
          .c {
            height: 1px;
            background: #4859a5;
          }
        }
        .leftBlock, .rightBlock {
          height: 1px !important;
          background: #000 !important;
        }
        .leftBegun, .rightBegun {
          background: #4859a5 !important;
          width: 16px !important;
          height: 16px !important;
          border-radius: 0 !important;
          top: -8px !important;
          border: none !important;
        }
      }
      .uf_buttons {
        text-align: left;
        margin: 0;
        button {
          display: block;
          background: url(/templates/metpromstroy/images/bg-category.png) !important;
          border: none !important;
          font-size: 16px;
          position: relative;
          padding: 12px 26px !important;
          margin-top: 15px;
          color: #000;
          margin-right: auto;
          width: 100%;
          text-align: left;
          &:before {
            content: '';
            position: absolute;
            width: 26px;
            height: 2px;
            display: block;
            background: #000;
            left: -11px;
            top: calc(50% - 1px);
          }
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: rotate(0);
            font-size: 22px;
            top: calc(50% - 10px);
            right: 20px;
            position: absolute;
          }
          &.groupbtnleft {
            &:after {
              content: "\46";
              color: #008000;
            }
          }
          &.groupbtnright {
            &:after {
              content: "\47";
              color: #ff0000;
            }
          }
        }
      }
    }
  }
}