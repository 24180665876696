.toggle-products {
  position: relative;
  padding-right: 25px !important;
  display: block;
  &:before {
    font-family: "metpromstroy" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\7a";
    color: #6372b2;
    font-size: 16px;
    top: 2px;
    right: 0;
    position: absolute;
  }
}
#products-menu {
  background: #fff;
  z-index: 1000;
  margin-bottom: 15px;
  .header-menu {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 32px;
    color: #4859a5;
    border-bottom: 2px solid #4859a5;
    padding: 23px 0;
    position: relative;
    margin-bottom: 24px;
    #closed {
      position: absolute;
      right: 0;
      top: 28px;
      cursor: pointer;
    }
  }
  .menu {
    overflow-y: auto;
    height: 100%;
    position: relative;
    > li {
      display: flex;
      align-items: stretch;
      position: static;
      padding: 40px 0;
      ul {
        width: 50%;
        display: none;
        position: absolute;
        right: 0;
        opacity: 0;
        list-style: none;
        margin: 0;
        z-index: 9;
        top: 0;
        bottom: 0;
        border-left: 1px solid #000;
        background: #fff;
        padding: 70px 30px;
        > li {
          > a {
            color: #000;
            margin-bottom: 18px;
            padding: 0;
            font-size: 18px;
            text-decoration: none;
            position: relative;
            transition: all .3s linear;
            &:before {
              content: '';
              position: absolute;
              width: 0;
              height: 1px;
              left: 0;
              top: 50%;
              background: #000;
              transition: width .3s linear;
            }
            &:hover {
              color: #4859a5;
              padding-left: 30px;
              transition: all .3s linear;
              &:before {
                width: 25px;
                transition: width .3s linear;
              }
            }
          }
        }
      }
      > a {
        width: 50%;
        background: transparent;
        padding: 0;
        padding-left: 85px;
        font-size: 18px;
        color: #000;
        i {
          color: #4859a5;
          font-size: 74px;
          display: inline-block;
          margin-top: -74px;
          position: relative;
          top: 33px;
          margin-right: 15px;
          line-height: 1;
        }
        &:hover {
         }
        &:before {
          content: '';
          position: absolute;
          width: 68px;
          height: 2px;
          left: 0;
          top: calc(50% - 1px);
          background: #4859a5;
        }
        span {
          position: absolute;
          width: 0;
          height: 1px;
          margin-left: 15px;
          top: 50%;
          background: #000;
          transition: all .3s linear;
        }
      }
      &:hover {
        > a {
          color: #4859a5;
          i {
            color: #00b7f4;
          }
          span {
            width: 100%;
            transition: all .3s linear;
          }
        }
        > ul {
          display: block;
          opacity: 1;
          transition: all .3s linear;
          z-index: 2;
        }
      }
      &.active {
        > a {
          span {
            width: 100%;
            transition: all .3s linear;
          }
        }
        > ul {
          display: block;
          opacity: 1;
          z-index: 1;
        }
      }
    }
  }
}