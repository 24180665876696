.fixed-menu {
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 1050;
  transition: all 0.4s ease;
  #header {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .3);
    border-top: 3px solid #4859a5;
    .top-header {
      .navigation {
        .navbar.navbar-default {
          .navbar-collapse {
            ul {
              > li {
                &.parent {
                  > a:not(.toggle-products) {
                    padding-right: 25px;
                    &:before {
                      font-family: "metpromstroy" !important;
                      font-style: normal !important;
                      font-weight: normal !important;
                      font-variant: normal !important;
                      text-transform: none !important;
                      speak: none;
                      line-height: 1;
                      -webkit-font-smoothing: antialiased;
                      -moz-osx-font-smoothing: grayscale;
                      content: "\61";
                      color: #6372b2;
                      font-size: 16px;
                      right: 0;
                      top: 3px;
                      position: absolute;
                    }
                  }
                }
                > a {
                  & + .nav-child {
                    display: none;
                    position: absolute;
                    border-left: 1px solid #4859a5;
                    box-shadow: 0 10px 16px -8px rgba(0, 0, 0, 0.15);
                    z-index: 1;
                    background: #fff;
                    top: 100%;
                    > li {
                      display: block;
                      float: none;
                      > a {
                        &:hover {
                          color: #6372b2;
                        }
                      }
                    }
                  }
                  &:hover {
                    text-decoration: none;
                    color: #4859a5;
                  }
                }
                &:hover {
                  > a:not(.toggle-products) {
                    & + .nav-child {
                      display: block;
                      padding-left: 0;
                      width: 225px;
                      > li {
                        padding: 8px;
                        margin-left: 15px;
                        position: relative;
                        > a {
                          font-size: 16px;
                          color: #000;
                          transition: all .3s linear;
                          &:hover {
                            color: #4859a5;
                            padding-left: 30px;
                            transition: all .3s linear;
                          }
                        }
                        &:before {
                          &:hover {
                            content: '';
                            position: absolute;
                            width: 11px;
                            height: 7px;
                            border-radius: 50%;
                            background: #000;
                            left: -9px;
                            opacity: 1;
                            top: 0;
                            margin: 19px 0 0 !important;
                            transition: 0.7s ease-in-out;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}