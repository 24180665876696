.wrapper-box {
  h3 {
    color: #4859a5;
    font-size: 32px;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  .newsflash {
    display: flex;
    flex-wrap: nowrap;
    .news-item {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      .news-item-bg {
        display: flex;
        background: url(/templates/metpromstroy/images/bg-category.png);
        padding: 23px 5px;
        margin-left: 68px;
        &:after {
          content: "";
        }
        .news-item-img {
          position: relative;
          left: -68px;
        }
        .news-item-more {
          position: relative;
          font-size: 16px;
          display: inline-block;
          overflow: hidden;
          a {
            color: #000;
            font-size: 16px;
            text-decoration: none !important;
            display: inline-block;
            position: relative;
            transition: color 0.2s ease-out 0s;
            &:after {
              font-family: "metpromstroy" !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              line-height: 1;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\70";
              font-size: 24px;
              color: #000;
              position: relative;
              top: -6px;
              right: -7px;
              transition: right 0.2s ease-out 0s;
            }
            &:hover {
              color: #4859a5;
              transition: color 0s ease-in 0.3s;
              &:after {
                right: -10px;
                transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
              }
            }
            span {
              overflow: hidden;
              display: inline-block;
              position: relative;
              padding: 0 0 7px;
              &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 68px;
                height: 1px;
                background-color: #000;
                transition: all 0.4s ease;
              }
              &:hover {
                &:before {
                  left: 100%;
                  background-color: #00b7f4;
                  transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
                }
              }
            }
          }
        }
        .news-item-box {
          margin-left: -68px;
          padding-left: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
}