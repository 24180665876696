#articles {
  .articles-box {
    margin-top: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    .item {
      width: calc(50% - 30px);
      margin: 15px 15px 60px 15px;
      background: #fff;
      display: flex;
      .image {
        width: 50%;
        height: 240px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
        z-index: 1;
      }
      .text {
        width: 50%;
        margin-left: -68px;
        margin-top: -35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .title {
        font-size: 18px;
        line-height: 20px;
        color: #2b3542;
        padding: 40px;
        padding-left: 85px;
        margin-right: -68px;
        height: 75%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: url(/templates/metpromstroy/images/bg-category.png) !important;
      }
      .more {
        display: block;
        padding: 12px 12px 6px 0;
        margin-left: 85px;
        margin-right: -68px;
        text-decoration: none;
        position: relative;
        a {
          color: #000;
          font-size: 16px;
          text-decoration: none !important;
          display: inline-block;
          position: relative;
          transition: color 0.2s ease-out 0s;
          &:after {
            font-family: "metpromstroy" !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\70";
            font-size: 24px;
            color: #000;
            position: relative;
            top: -6px;
            right: -7px;
            transition: right 0.2s ease-out 0s;
          }
          &:hover {
            color: #4859a5;
            transition: color 0s ease-in 0.3s;
            &:after {
              right: -10px;
              transition: right 0.175s ease-in 0.2s, color 0.1s ease-in 0.3s;
            }
          }
          span {
            overflow: hidden;
            display: inline-block;
            position: relative;
            padding: 0 0 7px;
            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 68px;
              height: 1px;
              background-color: #000;
              transition: all 0.4s ease;
            }
            &:hover {
              &:before {
                left: 100%;
                background-color: #00b7f4;
                transition: background-color 0.175s ease-in 0.2s, left 0.3s ease;
              }
            }
          }
        }
      }
      /*.more {
        font-size: 16px;
        display: block;
        padding: 12px 12px 6px 0;
        margin-left: 85px;
        margin-right: -68px;
        text-decoration: none;
        position: relative;
        color: #000;
        i {
          font-size: 18px;
          position: relative;
          top: 4px;
          margin-left: 15px;
        }
        &:after {
          position: absolute;
          content: '';
          width: 67px;
          height: 1px;
          background: #000;
          left: 0;
          bottom: 0;
          transition: width .3s linear;
        }
        &:hover {
          &:after {
            width: 0;
            transition: width .3s linear;
          }
        }
      }*/
    }
  }
}