#description {
  padding-top: 60px;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .title {
    font-size: 24px;
    padding-left: 95px;
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 85px;
      height: 2px;
      background-color: #4859a5;
      left: 0;
      top: calc(50% - 1px);
    }
  }
  .officials {
    margin-top: 45px;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
    .slick-list {
      margin-left: 30px;
      margin-right: 30px;
      width: calc(100% - 60px);
    }
    .slick-next {
      right: -5px;
    }
    .slick-prev {
    }
    .slick-next:before, .slick-prev:before {
      font-size: 24px;
    }
  }
}